<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="#18246f" stroke-width="0px">
    <path
      d="M49.44141,51.77783H14.55859c-5.27051,0-9.55859-4.28809-9.55859-9.55859v-20.43848c0-5.27051,4.28809-9.55859,9.55859-9.55859h34.88281c5.27051,0,9.55859,4.28809,9.55859,9.55859v20.43848c0,5.27051-4.28809,9.55859-9.55859,9.55859ZM14.55859,16.22217c-3.06494,0-5.55859,2.49365-5.55859,5.55859v20.43848c0,3.06494,2.49365,5.55859,5.55859,5.55859h34.88281c3.06494,0,5.55859-2.49365,5.55859-5.55859v-20.43848c0-3.06494-2.49365-5.55859-5.55859-5.55859H14.55859Z"
    />
    <path
      d="M26.44434,43.51123c-.3291,0-.65918-.08105-.9585-.24463-.64209-.35059-1.0415-1.02393-1.0415-1.75537v-20c0-.73145.39941-1.40479,1.0415-1.75537.64209-.35156,1.4248-.32275,2.04004.07324l15.55566,10c.57227.36768.91846,1.00146.91846,1.68213s-.34619,1.31445-.91846,1.68213l-15.55566,10c-.32861.21143-.70459.31787-1.08154.31787ZM28.44434,25.17432v12.67383l9.85693-6.33691-9.85693-6.33691Z"
    />
  </svg>
</template>
